<template>
  <v-container>
    <div id="searchZone">
      <v-select 
        class="category"
        :items="categories"
        :item-text="'text'"
        :item-value="'code'"
        v-model="category"
      >
      </v-select>
      <v-text-field
        label="Objet"
        v-model="search"
        @change="searchUpdated"
      ></v-text-field>
      <v-text-field
        class="coefficient"
        label="Coefficient"
        v-model="coeff"
        :disabled="fixedCoeff"
      ></v-text-field>
    </div>
    <div id="dataZone">
      <v-card v-if="objectSelected">
        <v-card-title>{{objectSelected.name}}</v-card-title>
        <v-card-text>
          <v-row
            align="center"
            class="mx-0"
          >
            <div class="grey--text ml-4">
              Niveau {{objectSelected.level}}
            </div>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-subtitle class="pb-0 text--primary">Stats</v-card-subtitle>
        <v-card-text>
          <div
            align="left"
            class="grey--text"
            v-for="stat of objectSelected.stats"
            :key="stat.name"
          >
            {{stat.name}} : {{stat.min}} <span v-if="stat.max">- {{stat.max}}</span>
          </div>
        </v-card-text>
        <v-divider v-if="this.unknownRunes.length"></v-divider>
        <v-card-subtitle class="pb-0 text--primary" v-if="this.unknownRunes.length">Stats inconnues</v-card-subtitle>
        <v-card-text>
          <div
            align="left"
            class="grey--text"
            v-for="stat of this.unknownRunes"
            :key="stat.name"
          >
            {{stat.name}}
          </div>
        </v-card-text>
      </v-card>
      <v-card v-if="this.knownRunes.length">
        <v-card-title>Prix moyen runes liées</v-card-title>
        <v-card-text>
          <div
            align="left"
            class="grey--text"
            v-for="knownRune of knownRunes"
            :key="knownRune.id"
          >
            <v-text-field
              :label="knownRune.id"
              :value="getPrice(knownRune.id)"
              @change="setPrice(knownRune.id, $event)"
            ></v-text-field>
          </div>
        </v-card-text>
      </v-card>
      <v-card v-if="objectSelected && (!this.unknownRunes || !this.unknownRunes.length)">
        <v-card-title>Résultat sans focus</v-card-title>
        <v-card-text>
          Coefficient : {{this.getTechnicalCoeff}}
        </v-card-text>
        <v-card-text>
          <div
            v-for="stat of getResultNoFocus().stats"
            :key="stat.name"
          >
            <div>
              <div class="font-weight-normal">
                <strong>{{stat.name}}</strong> {{stat.minRunes}} - {{stat.maxRunes}} runes
              </div>
              <div>{{stat.minKamas}}k - {{stat.maxKamas}}k</div>
            </div>
            <v-divider></v-divider> 
          </div>
        </v-card-text>
        <v-card-text>
          Total : {{getResultNoFocus().totalMin}}k - {{getResultNoFocus().totalMax}}k
        </v-card-text>
      </v-card>
      <v-card v-if="objectSelected && (!this.unknownRunes || !this.unknownRunes.length)">
        <v-card-title>Résultat avec focus</v-card-title>
        <v-card-text v-for="resultFocus of getResultsFocus()" :key="resultFocus.rune">
          <div
              v-for="stat of resultFocus.stats"
              :key="stat.name"
            >
              <div>
                <div class="font-weight-normal">
                  <strong>{{stat.name}}</strong> {{stat.minRunes}} - {{stat.maxRunes}} runes
                </div>
                <div>{{stat.minKamas}}k - {{stat.maxKamas}}k</div>
              </div>
              <v-divider></v-divider> 
            </div>
        </v-card-text>
      </v-card>
    </div>
  </v-container>
</template>

<script>
  const axios = require('axios');

  export default {
    name: 'Brisage',

    data: () => ({
      search: undefined,
      category: 'equipments',
      objectSelected: undefined,
      coeff: 100,
      fixedCoeff: false,
      elements: [
        {
          id: 'Vitalité',
          poids: 1,
          quantiteParRune: 5
        },
        {
          id: 'Agilité',
          poids: 1,
          quantiteParRune: 1
        },
        {
          id: 'Intelligence',
          poids: 1,
          quantiteParRune: 1
        },
        {
          id: 'Force',
          poids: 1,
          quantiteParRune: 1
        },
        {
          id: 'Chance',
          poids: 1,
          quantiteParRune: 1
        },
        {
          id: 'Initiative',
          poids: 1,
          quantiteParRune: 10
        },

        {
          id: 'Sagesse',
          poids: 3,
          quantiteParRune: 1
        },

        {
          id: 'Prospection',
          poids: 3,
          quantiteParRune: 1
        },
        
        {
          id: 'Puissance',
          poids: 2,
          quantiteParRune: 1
        },
        
        {
          id: 'Résistance Neutre',
          poids: 2,
          quantiteParRune: 1
        },
        {
          id: 'Résistance Feu',
          poids: 2,
          quantiteParRune: 1
        },
        {
          id: 'Résistance Eau',
          poids: 2,
          quantiteParRune: 1
        },
        {
          id: 'Résistance Air',
          poids: 2,
          quantiteParRune: 1
        },
        {
          id: 'Résistance Terre',
          poids: 2,
          quantiteParRune: 1
        },
        
        {
          id: '% Résistance Neutre',
          poids: 6,
          quantiteParRune: 1
        },
        {
          id: '% Résistance Feu',
          poids: 6,
          quantiteParRune: 1
        },
        {
          id: '% Résistance Eau',
          poids: 6,
          quantiteParRune: 1
        },
        {
          id: '% Résistance Air',
          poids: 6,
          quantiteParRune: 1
        },
        {
          id: '% Résistance Terre',
          poids: 6,
          quantiteParRune: 1
        },
        
        {
          id: 'Résistance Critiques',
          poids: 2,
          quantiteParRune: 1
        },
        {
          id: 'Résistance Poussée',
          poids: 2,
          quantiteParRune: 1
        },
        
        {
          id: 'Esquive PA',
          poids: 7,
          quantiteParRune: 1
        },
        {
          id: 'Esquive PM',
          poids: 7,
          quantiteParRune: 1
        },
        {
          id: 'Retrait PA',
          poids: 7,
          quantiteParRune: 1
        },
        {
          id: 'Retrait PM',
          poids: 7,
          quantiteParRune: 1
        },
        
        {
          id: 'Pods',
          poids: 2.5,
          quantiteParRune: 10
        },
        
        {
          id: 'Tacle',
          poids: 4,
          quantiteParRune: 1
        },
        {
          id: 'Fuite',
          poids: 4,
          quantiteParRune: 1
        },

        {
          id: 'Dommages',
          poids: 5,
          quantiteParRune: 1
        },

        {
          id: 'Dommages Neutre',
          poids: 5,
          quantiteParRune: 1
        },
        {
          id: 'Dommages Air',
          poids: 5,
          quantiteParRune: 1
        },
        {
          id: 'Dommages Terre',
          poids: 5,
          quantiteParRune: 1
        },
        {
          id: 'Dommages Feu',
          poids: 5,
          quantiteParRune: 1
        },
        {
          id: 'Dommages Eau',
          poids: 5,
          quantiteParRune: 1
        },
        {
          id: 'Dommages Pièges',
          poids: 5,
          quantiteParRune: 1
        },
        
        {
          id: '% Dommages d\'armes',
          poids: 15,
          quantiteParRune: 1
        },
        {
          id: '% Dommages mêlée',
          poids: 15,
          quantiteParRune: 1
        },
        {
          id: '% Dommages distance',
          poids: 15,
          quantiteParRune: 1
        },
        {
          id: '% Dommages aux sorts',
          poids: 15,
          quantiteParRune: 1
        },
        {
          id: '% Résistance mêlée',
          poids: 15,
          quantiteParRune: 1
        },
        {
          id: '% Résistance distance',
          poids: 15,
          quantiteParRune: 1
        },
        
        {
          id: 'Puissance (pièges)',
          poids: 2,
          quantiteParRune: 1
        },
        
        {
          id: 'Soins',
          poids: 10,
          quantiteParRune: 1
        },
        {
          id: 'Coups Critiques',
          poids: 10,
          quantiteParRune: 1
        },
        {
          id: '% Critique',
          poids: 10,
          quantiteParRune: 1
        },
        {
          id: 'Renvoie  dommages',
          poids: 10,
          quantiteParRune: 1
        },

        {
          id: 'Invocations',
          poids: 30,
          quantiteParRune: 1
        },
        {
          id: 'PO',
          poids: 51,
          quantiteParRune: 1
        },
        {
          id: 'PA',
          poids: 90,
          quantiteParRune: 1
        },
        {
          id: 'PM',
          poids: 100,
          quantiteParRune: 1
        }
      ],
      categories: [
        {
          'text': 'Equipement',
          'code': 'equipments'
        },
        {
          'text': 'Armes',
          'code': 'weapons'
        }
      ]
    }),

    created() {
    },
    computed: {
      unknownRunes: function () {
        if (this.objectSelected) {
          return this.objectSelected.stats.filter(stat => !stat.name.startsWith('(') && stat.min > 0 && !this.elements.some(elem => stat.name.toUpperCase() ===elem.id.toUpperCase()))
        }
        return []
      },
      knownRunes: function () {
        if (this.objectSelected) {
          return this.elements.filter(elem => this.objectSelected.stats.some(stat => !stat.name.startsWith('(') && stat.min > 0 && stat.name.toUpperCase() === elem.id.toUpperCase()))
        }
        return []
      },
      getTechnicalCoeff: function () {
        return 2.55 * (this.objectSelected.level / 100) * (this.coeff / 100) * 0.6
      }
    },
    methods: {
      searchUpdated: function (value) {
        if (value && !isNaN(value)) {
          axios.get(`https://fr.dofus.dofapi.fr/` + this.category + `/` + value).then(response => {
            if (response.data) {
              this.objectSelected = this.moveStats(response.data)
                this.coeff = 100;
                this.fixedCoeff = false;
              if (this.objectSelected.recipe.length === 0) {
                this.coeff = 50;
                this.fixedCoeff = true;
              }
            } else {
              this.objectSelected = undefined;
            }
          })
          .catch(e => {
            console.log(e);
            alert(e)
          })
        } else {
          axios.get(`https://fr.dofus.dofapi.fr/` + this.category + `?filter[where][name]=` + value).then(response => {
            if (response.data && response.data[0]) {
              this.objectSelected = this.moveStats(response.data[0])
                this.coeff = 100;
                this.fixedCoeff = false;
              if (this.objectSelected.recipe.length === 0) {
                this.coeff = 50;
                this.fixedCoeff = true;
              }
            } else {
              this.objectSelected = undefined;
            }
          })
          .catch(e => {
            console.log(e);
            alert(e)
          })
        }
      },
      getStats: function (statistic) {
        return Object.keys(statistic)[0]
      },
      getMatchingStat: function (statisticName) {
        const perfectMatchElem = this.elements.find(elem => statisticName.toUpperCase() === elem.id.toUpperCase());
        if (perfectMatchElem) {
          return perfectMatchElem.id;
        }
        const partialMatchElem = this.elements.find(elem => statisticName.toUpperCase().includes(elem.id.toUpperCase()));
        if (partialMatchElem) {
          console.log('Partial match : ', statisticName, partialMatchElem);
          return partialMatchElem.id;
        }
        console.log('No match : ', statisticName);
        return statisticName;
      },
      moveStats: function (object) {
        if (object.statistics) {
          object.stats = [];
          for (let statistic of object.statistics) {
            const stat = this.getStats(statistic);
            object.stats.push({
              name: this.getMatchingStat(stat),
              min: statistic[stat].min,
              max: statistic[stat].max ? statistic[stat].max : statistic[stat].min
            })
          }
          object.statistics = undefined
        }
        return object
      },
      getPrice: function (stat) {
        if (localStorage.getItem(stat)) {
          return +localStorage.getItem(stat)
        }
        return 0
      },
      setPrice: function (stat, value) {
        localStorage.setItem(stat, value);
      },
      getStatFromItem: function (statistic) {
        if (this.objectSelected) {
          return this.objectSelected.stats.find(stat => stat.name.toUpperCase() === statistic.toUpperCase()) || this.objectSelected.stats.find(stat => stat.name.toUpperCase() === statistic.toUpperCase())
        }
        return {}
      },
      getRuneQuantityFromItem: function (amount, stat, coeff) {
        return (amount / stat.quantiteParRune) * coeff
      },
      getResultNoFocus: function () {
        if (this.knownRunes) {
          const result = { stats: [], totalMin: 0, totalMax: 0 };
          for (const knownRune of this.knownRunes) {
            const minRunes = this.getRuneQuantityFromItem(this.getStatFromItem(knownRune.id).min, knownRune, this.getTechnicalCoeff)
            const maxRunes = this.getRuneQuantityFromItem(this.getStatFromItem(knownRune.id).max, knownRune, this.getTechnicalCoeff)
            const min = Math.round(this.getPrice(knownRune.id) * minRunes);
            const max = Math.round(this.getPrice(knownRune.id) * maxRunes);
            result.totalMin += min;
            result.totalMax += max;
            result.stats.push({
              name: knownRune.id,
              minKamas: min,
              maxKamas: max,
              minRunes: Math.round(minRunes),
              maxRunes: Math.round(maxRunes)
            })
          }
          return result;
        }
      },
      getResultsFocus: function () {
        if (this.knownRunes) {
          const results = [];

          let poidsMinTotal = 0;
          let poidsMaxTotal = 0;
          for (const knownRune of this.knownRunes) {
            const stats = this.getStatFromItem(knownRune.id);
            if (stats.min > 0) {
              poidsMinTotal += ((stats.min / knownRune.quantiteParRune) * knownRune.poids) / 2;
              poidsMaxTotal += ((stats.max / knownRune.quantiteParRune) * knownRune.poids) / 2;
            }
          }

          for (const knownRune of this.knownRunes) {
            const stats = this.getStatFromItem(knownRune.id);
            if (stats.min > 0) {
              const poidsMinTotalHorsLigne = poidsMinTotal - ((stats.min / knownRune.quantiteParRune) * knownRune.poids) / 2;
              const runesMinAAjouterLigne = (poidsMinTotalHorsLigne / knownRune.poids) * knownRune.quantiteParRune;
              const totalStatMinLigneIncluantFocus = stats.min + runesMinAAjouterLigne;
              const minRunes = this.getRuneQuantityFromItem(totalStatMinLigneIncluantFocus, knownRune, this.getTechnicalCoeff)
              const minKamas = Math.round(this.getPrice(knownRune.id) * minRunes);

              const poidsMaxTotalHorsLigne = poidsMaxTotal - ((stats.max / knownRune.quantiteParRune) * knownRune.poids) / 2;
              const runesMaxAAjouterLigne = (poidsMaxTotalHorsLigne / knownRune.poids) * knownRune.quantiteParRune;
              const totalStatMaxLigneIncluantFocus = stats.max + runesMaxAAjouterLigne;
              const maxRunes = this.getRuneQuantityFromItem(totalStatMaxLigneIncluantFocus, knownRune, this.getTechnicalCoeff)
              const maxKamas = Math.round(this.getPrice(knownRune.id) * maxRunes);

              const result = { 
                rune: knownRune.id, 
                stats: [
                  {
                    name: knownRune.id,
                    minKamas: minKamas,
                    maxKamas: maxKamas,
                    minRunes: Math.round(minRunes),
                    maxRunes: Math.round(maxRunes)
                  }
                ], 
                totalMin: minKamas, 
                totalMax: maxKamas 
              }
              results.push(result)
            }
          }

          results.sort((res1, res2) => res2.totalMax - res1.totalMax)

          return results;
        }
      }
    }
  }
</script>
<style scoped>
  #searchZone {
    display: flex;
  }

  #dataZone {
    display: flex;
  }

  .category {
    margin-right: 10px;
    max-width: 200px;
  }

  .coefficient {
    margin-left: 10px;
    max-width: 100px;
  }
</style>